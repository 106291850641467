<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'

const props = withDefaults(
  defineProps<{
    loading: boolean
    firstName: string
    lastName: string
  }>(),
  {
    loading: false,
    firstName: '',
    lastName: '',
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DialogVisualSignatureData']['contextData']): void
}>()

const { t } = useI18n()

const data = reactive({
  firstName: '',
  lastName: '',
})

const submit = () => {
  emit('action', { firstName: data.firstName, lastName: data.lastName })
}

onMounted(() => {
  data.firstName = props.firstName
  data.lastName = props.lastName
})
</script>

<template>
  <base-dialog
    data-cy="visualSignatureDataDialog"
    max-width="630"
    :title="t('viewer.visual_signature.dialog.title')"
    :disabled="loading"
    :action-text="t('global.save')"
    :cancel-text="t('global.close')"
    @action="submit"
    @close="emit('close')"
  >
    {{ $t('viewer.visual_signature.dialog.text') }}

    <v-text-field
      v-model="data.firstName"
      class="mt-6"
      variant="filled"
      autocomplete="given-name"
      :label="`${$t('global.first_name')} (${$t('global.optional')})`"
    />

    <v-text-field
      v-model="data.lastName"
      variant="filled"
      autocomplete="family-name"
      :label="`${$t('global.last_name')} (${$t('global.optional')})`"
    />
  </base-dialog>
</template>
